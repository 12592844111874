import {
  ButtonSpinner,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Select,
  useToast,
} from '@chakra-ui/react';
import { BaseSyntheticEvent, ChangeEvent, useCallback, useState } from 'react';
import { MdSend } from 'react-icons/md';
import { useTheme } from 'styled-components';

import api, { Service } from '../../../services/api';
import ErrorHandler from '../../../utils/errorHandler';
import BoxSection from '../../BoxSection';
import Button from '../../Button';
import Input from '../../Input';

import { useTranslation } from 'react-i18next';
import { IPropsCreateTickets } from '../../../interfaces/Support';

const TICKET_STATUS = [
  {
    title: 'In Progress',
    value: 'in_progress',
  },
  {
    title: 'Pending',
    value: 'pending',
  },
  {
    title: 'Refused',
    value: 'refused',
  },
  {
    title: 'Done',
    value: 'done',
  },
];

export default function UpdateTicketModal(
  props: Readonly<IPropsCreateTickets>,
) {
  const [isLoading, setIsLoading] = useState(false);
  const [ticketID, setTicketID] = useState('');
  const [selectedTicketStatus, setSelectedTicketStatus] = useState('');

  const theme = useTheme();
  const toast = useToast();
  const { t } = useTranslation('support');

  const updateTicketStatus = useCallback(
    async (e: BaseSyntheticEvent) => {
      try {
        e.preventDefault();

        setIsLoading(true);

        const body = {
          status: selectedTicketStatus,
          kleverResponse: '',
        };

        const response = await api.patch({
          route: `support-tickets/${ticketID}`,
          service: Service.KRYPTO_BANKING,
          apiVersion: 'v1',
          body,
        });

        if (response?.message?.length) throw response.message;

        setTicketID('');
        setSelectedTicketStatus('');
        if (props?.handleReload) props.handleReload();
        return toast({
          title: t('common:toasts.titles.success'),
          description: t('common:toasts.body.successSent'),
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      } catch (e) {
        return toast({
          title: t('common:toasts.titles.error'),
          description: ErrorHandler(
            'adminAdministrators',
            e || 'Something went wrong. Please try again later',
          ),
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [ticketID, selectedTicketStatus, props, toast, t],
  );

  return (
    <BoxSection m="auto" width="100%" mt={1}>
      <Heading
        as="h3"
        size="lg"
        textAlign={'center'}
        color={theme.common.lightText}
        mb={5}
      >
        Update Ticket
      </Heading>

      <Flex as="form" flexDirection={'column'} onSubmit={updateTicketStatus}>
        <FormControl>
          <FormLabel htmlFor="ticket_id" color={theme.common.lightText} mt={2}>
            Ticket ID
          </FormLabel>

          <Input
            type="number"
            id="ticket_id"
            min={0}
            inputType="modal"
            placeholder="Enter the ticket ID"
            isDisabled={isLoading}
            value={ticketID}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTicketID(e.target.value)
            }
            mb={0}
            bg="#efefef !important"
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="status" color={theme.common.lightText} mt={4}>
            Ticket Status
          </FormLabel>

          <Select
            id="type"
            placeholder={t('form.fields.selectDefaultTypeOption')}
            disabled={isLoading}
            value={selectedTicketStatus}
            onChange={(e: any) => setSelectedTicketStatus(e.target.value)}
            bg={theme.input.background}
          >
            {TICKET_STATUS.map(status => (
              <option key={status.value} value={status.value}>
                {status.title}
              </option>
            ))}
          </Select>
        </FormControl>

        <Button
          type="submit"
          mt={12}
          rightIcon={!isLoading && <MdSend />}
          isDisabled={
            isLoading || !ticketID?.length || !selectedTicketStatus?.length
          }
        >
          {isLoading ? <ButtonSpinner /> : 'Update'}
        </Button>
      </Flex>
    </BoxSection>
  );
}
