import { InputProps as CInputProps } from '@chakra-ui/react';
import type { FC } from 'react';
import { StyledInput, StyledInputModal, StyledInputMyAccount } from './styles';

type InputProps = CInputProps & {
  inputType?: 'modal' | 'account';
};

const Input: FC<InputProps> = ({ inputType, ...props }) => {
  switch (inputType) {
    case 'modal':
      return <StyledInputModal {...props} />;
    case 'account':
      return <StyledInputMyAccount {...props} />;
    default:
      return <StyledInput {...props} />;
  }
};

export default Input;
