import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  ListItem,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useToast,
} from '@chakra-ui/react';
import { CaretDown, CaretUp, CheckCircle, XCircle } from 'phosphor-react';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import BoxSection from '../../components/BoxSection';
import BoxStyled from '../../components/BoxStyled';
import Container from '../../components/Container';
import { Title } from '../../components/Filter/Title';
import Input from '../../components/Input';
import Nav from '../../components/Navbar';
import { UNKNOWN_VALUE } from '../../constants';
import { CHAINS } from '../../constants/chains';
import api, { Service } from '../../services/api';
import { truncateString } from '../../utils/formatters';

type Token = {
  id: number;
  name: string;
  abbr: string;
  precison: number;
  creator: string;
  createdAt: number;
  updatedAt: number;
};

type Network = {
  id: number;
  name: string;
  chain: number;
  confirmations: number;
  createdAt: number;
  updatedAt: number;
};
interface ITokensNetworks {
  token: Token;
  network: Network;
  assetId: string;
  withdrawFeeToken: Token;
  withdrawFeeAmount: number;
  minWithdraw: string;
  minDeposit: string;
  BurnFeeAmount: string;
  isMainCoin: boolean;
  maxColdAccountsTransfer: number;
  minCheckBalanceAmountUsd: number;
  sendCheckBalanceUsd: number;
  minSendColdAccountAmountUsd: number;
  activeWithdraw: boolean;
  activeDeposit: boolean;
}

type PaginationData = {
  page: number;
  totalPages: number;
  limit: number;
};

type TokensNetworksData = {
  data: Array<ITokensNetworks>;
} & PaginationData;

type Page = {
  current: number;
  total: number;
  limit: number;
};

const PAGE_INIT_STATE: Page = {
  current: 1,
  total: 1,
  limit: 10,
};

const TokensNetworks: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(PAGE_INIT_STATE);
  const [tokensNetworks, setTokensNetworks] = useState(
    [] as Array<ITokensNetworks>,
  );
  const [selectedChainId, setSelectedChainId] = useState(
    CHAINS[0].id.toString(),
  );
  const [contractAddress, setContractAddress] = useState('');
  const [collapsedRow, setCollapsedRow] = useState('');

  const theme = useTheme();
  const toast = useToast();

  const { t } = useTranslation('tokensNetworks');

  const isRowCollapsed = useCallback(
    (id: string) => collapsedRow === id,
    [collapsedRow],
  );

  const getTokensNetworks = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data, ...rest }: TokensNetworksData = await api.get({
        route: 'token-network/tokens',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        query: {
          page: page.current,
          limit: page.limit,
        },
      });

      if (!data?.length) return;

      setPage({
        current: rest.page,
        total: rest.totalPages,
        limit: rest.limit,
      });
      setTokensNetworks(data);
    } finally {
      setIsLoading(false);
    }
  }, [page.current, page.limit]);

  const onFilterTokensNetworks = useCallback(async () => {
    try {
      setIsLoading(true);

      const data = await api.get({
        route: 'token-network/search',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        query: {
          chainId: selectedChainId,
          assetId: contractAddress,
        },
      });

      if (!data) {
        toast({
          title: 'Something went wrong while filtering for the tokens-networks',
          status: 'error',
        });

        return;
      }

      setTokensNetworks([data]);
    } finally {
      setIsLoading(false);
    }
  }, [selectedChainId, contractAddress, getTokensNetworks]);

  const resetFiltersAndFetchTokensNetworks = useCallback(async () => {
    setSelectedChainId(CHAINS[0].id.toString());
    setContractAddress('');
    await getTokensNetworks();
  }, [getTokensNetworks]);

  const onSelectChainId = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    setSelectedChainId(value);
  };

  const onChangeContractAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setContractAddress(value);
  };

  const handleChangeToPreviousPage = () => {
    if (page.current <= 1) return;

    setPage(prev => ({
      ...prev,
      current: prev.current - 1,
    }));
  };

  const handleChangeToNextPage = () => {
    if (page.current >= page.total) return;

    setPage(prev => ({
      ...prev,
      current: prev.current + 1,
    }));
  };

  const handleChangePageLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    setPage(prev => ({
      ...prev,
      limit: +value,
    }));
  };

  const handleCollapseRow = (id: string) =>
    setCollapsedRow(prev => (prev !== id ? id : ''));

  useEffect(() => void getTokensNetworks(), [getTokensNetworks]);

  return (
    <Container>
      <Nav />

      <BoxStyled>
        <BoxSection>
          <Heading textAlign="center" color={theme.common.lightText}>
            Tokens-Networks
          </Heading>

          <Accordion allowToggle bg={theme.form.background} mt="2rem">
            <AccordionItem>
              <Title value="Filters" />

              <AccordionPanel display="flex" flexDirection="column" gap="1rem">
                <Box>
                  <label htmlFor="chain">
                    <Text fontWeight="bold">Chain</Text>
                  </label>
                  <Select
                    id="chain"
                    name="chain"
                    value={selectedChainId}
                    onChange={onSelectChainId}
                  >
                    {CHAINS.map(chain => (
                      <option key={chain.id} value={String(chain.id)}>
                        {chain.name}
                      </option>
                    ))}
                  </Select>
                </Box>

                <Box>
                  <label htmlFor="contractAddress">
                    <Text fontWeight="bold">Asset ID</Text>
                  </label>
                  <Input
                    id="contractAddress"
                    name="contractAddress"
                    isDisabled={!selectedChainId?.length}
                    value={contractAddress}
                    onChange={onChangeContractAddress}
                  />
                </Box>

                <Flex gap="2rem">
                  <Button
                    variant="solid"
                    disabled={!selectedChainId?.length}
                    onClick={onFilterTokensNetworks}
                    color="white"
                    bgColor={theme.colors.kleverPink.light}
                    _hover={{ bgColor: theme.colors.kleverPink.light }}
                    flex={1}
                  >
                    Submit
                  </Button>

                  <Button onClick={resetFiltersAndFetchTokensNetworks} flex={1}>
                    Reset
                  </Button>
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </BoxSection>

        <BoxSection>
          <Table variant="simple">
            <Thead>
              {!isLoading && (
                <Tr>
                  <Th>{t('table.header.token')}</Th>

                  <Th>{t('table.header.network')}</Th>

                  <Th>{t('table.header.assetId')}</Th>

                  <Th>{t('table.header.withdrawFeeToken')}</Th>

                  <Th>{t('table.header.withdrawFeeAmount')}</Th>

                  <Th>{t('table.header.activeWithdraw')}</Th>

                  <Th>{t('table.header.activeDeposit')}</Th>
                </Tr>
              )}
            </Thead>

            <Tbody>
              {isLoading && (
                <Tr>
                  <Td colSpan={4}>
                    <Flex justify="center">
                      <Spinner />
                    </Flex>
                  </Td>
                </Tr>
              )}

              {!isLoading && !tokensNetworks?.length && (
                <Tr>
                  <Td colSpan={7}>
                    <Flex justify="center">
                      <Text>{t('table.body.noTokensNetworks')}</Text>
                    </Flex>
                  </Td>
                </Tr>
              )}

              {!isLoading &&
                tokensNetworks?.length > 0 &&
                tokensNetworks.map(tn => (
                  <Fragment key={tn.token.id}>
                    <Tr
                      bg={
                        isRowCollapsed(`${tn.token.id}-${tn.network.id}`)
                          ? 'gray.200'
                          : 'inherit'
                      }
                    >
                      <Td>{tn?.token?.abbr || UNKNOWN_VALUE}</Td>

                      <Td>{tn?.network?.name || UNKNOWN_VALUE}</Td>

                      <Td>{truncateString(tn.assetId, 0, 4)}</Td>

                      <Td>{tn?.withdrawFeeToken?.abbr || UNKNOWN_VALUE}</Td>

                      <Td>{tn?.withdrawFeeAmount || UNKNOWN_VALUE}</Td>

                      <Td>
                        {tn.activeWithdraw ? (
                          <CheckCircle size={20} color="green" />
                        ) : (
                          <XCircle size={20} color="red" />
                        )}
                      </Td>

                      <Td>
                        {tn.activeDeposit ? (
                          <CheckCircle size={20} color="green" />
                        ) : (
                          <XCircle size={20} color="red" />
                        )}
                      </Td>

                      <Td>
                        <Button
                          onClick={() =>
                            handleCollapseRow(`${tn.token.id}-${tn.network.id}`)
                          }
                        >
                          {isRowCollapsed(`${tn.token.id}-${tn.network.id}`) ? (
                            <CaretUp size={14} />
                          ) : (
                            <CaretDown size={14} />
                          )}
                        </Button>
                      </Td>
                    </Tr>

                    {isRowCollapsed(`${tn.token.id}-${tn.network.id}`) && (
                      <Tr>
                        <Td colSpan={8} bg="gray.100">
                          <UnorderedList
                            display="flex"
                            flexDirection="column"
                            gap="1rem"
                          >
                            <ListItem>
                              {t('table.body.minWithdraw')}:{' '}
                              <b>
                                {tn?.minWithdraw
                                  ? `${tn.minWithdraw} ${tn.token.abbr}`
                                  : UNKNOWN_VALUE}
                              </b>
                            </ListItem>

                            <ListItem>
                              {t('table.body.minDeposit')}:{' '}
                              <b>
                                {tn?.minDeposit
                                  ? `${tn.minDeposit} ${tn.token.abbr}`
                                  : UNKNOWN_VALUE}
                              </b>
                            </ListItem>

                            <ListItem>
                              {t('table.body.burnFeeAmount')}:{' '}
                              <b>
                                {tn?.BurnFeeAmount
                                  ? `${tn.BurnFeeAmount} ${tn.token.abbr}`
                                  : UNKNOWN_VALUE}
                              </b>
                            </ListItem>
                          </UnorderedList>
                        </Td>
                      </Tr>
                    )}
                  </Fragment>
                ))}
            </Tbody>
          </Table>

          {!isLoading && (
            <Flex justify="center" align="center" gap={10} mt={4}>
              <IconButton
                aria-label="back-button"
                disabled={page.current <= 1}
                onClick={handleChangeToPreviousPage}
                icon={<ChevronLeftIcon w={6} h={6} />}
              />

              <Text>
                <b>{page.current}</b>/{page.total}
              </Text>

              <IconButton
                aria-label="next-button"
                disabled={page.current >= page.total}
                onClick={handleChangeToNextPage}
                icon={<ChevronRightIcon w={6} h={6} />}
              />

              <Flex align="center" ml={2}>
                <Select value={page.limit} onChange={handleChangePageLimit}>
                  {[10, 20, 50, 100].map(v => (
                    <option key={v} value={v}>
                      Show {v}
                    </option>
                  ))}
                </Select>
              </Flex>
            </Flex>
          )}
        </BoxSection>
      </BoxStyled>
    </Container>
  );
};

export default TokensNetworks;
