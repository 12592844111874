import React, { BaseSyntheticEvent, useCallback } from 'react';

import {
  Flex,
  FormControl,
  FormLabel,
  Heading,
  useToast,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import BoxSection from '../../BoxSection';

import { Plus } from 'phosphor-react';
import { ValidationError } from 'yup';
import { CHAINS } from '../../../constants/chains';
import { createNetworkSchema } from '../../../schemas/CreateNetworkSchema';
import api, { Service } from '../../../services/api';
import Button from '../../Button';
import Input from '../../Input';
import Select from '../../Select';

interface NetworkForm extends BaseSyntheticEvent {
  target: {
    name: HTMLInputElement;
    chain: HTMLInputElement;
    confirmations: HTMLInputElement;
  };
}

const CreateNetwork: React.FC<any> = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation('createNetworkToken');
  const theme = useTheme();
  const toast = useToast();

  const handleSubmit = useCallback(async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const { target } = e as NetworkForm;
    const { name, chain, confirmations } = target;

    const data = createNetworkSchema.cast({
      name: name.value,
      chain: chain.value,
      confirmations: confirmations.value,
    });

    try {
      await createNetworkSchema.validate(data, {
        abortEarly: false,
      });

      const response = await api.post({
        route: 'networks',
        service: Service.KRYPTO_BANKING,
        apiVersion: 'v1',
        body: {
          ...data,
        },
      });

      if (response?.data) {
        return toast({
          title: t('common:toasts.titles.success'),
          description: t('common:toasts.body.successSent'),
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }

      return toast({
        title: t('common:toasts.titles.error'),
        description: t('network.fields.failed'),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } catch (err: any) {
      if (err instanceof ValidationError) {
        toast({
          title: t('common:toasts.titles.error'),
          description: err.errors[0],
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <BoxSection>
      <Heading textAlign={'center'} color={theme.common.lightText} mb="2rem">
        {t('network.title')}
      </Heading>
      <Flex as="form" onSubmit={handleSubmit} flexDir="column" aria-required>
        <Flex gap={2} flex={1} w="100%">
          <FormControl>
            <FormLabel>{t('network.fields.name')}</FormLabel>
            <Input name="name" />
          </FormControl>

          <FormControl>
            <FormLabel>{t('network.fields.confirmations')}</FormLabel>
            <Input name="confirmations" type="number" />{' '}
          </FormControl>
        </Flex>

        <Select name="chain" required>
          {CHAINS.map(chain => (
            <option key={chain.id} value={chain.id}>
              {chain.name}
            </option>
          ))}
        </Select>

        <Button
          leftIcon={<Plus size={18} />}
          alignSelf={'streetch'}
          type="submit"
          isLoading={isLoading}
        >
          {t('network.fields.submit')}
        </Button>
      </Flex>
    </BoxSection>
  );
};

export default CreateNetwork;
