import {
  Box,
  Flex,
  FormLabel,
  Heading,
  Skeleton,
  Switch,
  Text,
  Textarea,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdContentCopy, MdOutlineCheck, MdSend } from 'react-icons/md';
import { useTheme } from 'styled-components';

import { IProsDepositInfoModal } from '../../../interfaces/Support';
import api, { Service } from '../../../services/api';
import ErrorHandler from '../../../utils/errorHandler';
import BoxSection from '../../BoxSection';
import Button from '../../Button';
import Input from '../../Input';

import { useParams } from 'react-router-dom';
import { ABLED_BUTTON_SEND_TICKET_MODAL } from '../../../constants';

export default function DepositInfoModal(props: IProsDepositInfoModal) {
  const [btnCopy, setBtnCopy] = useState<any>({});
  const [textAreaValues, setTextAreaValue] = useState('');
  const { t } = useTranslation('tables');
  const { id } = useParams();
  const theme = useTheme();
  const toast = useToast();

  const data_array = [
    { type: 'id', info: props.data?.id },
    { type: 'fromAddress', info: props.data?.fromAddress },
    { type: 'toAddress', info: props.data?.toAddress },
    { type: 'txId', info: props.data?.txId },
    { type: 'isNft', info: props.data?.isNft },
    { type: 'tokenHash', info: props.data?.tokenHash },
    { type: 'error', info: props.data?.error },
    {
      type: 'status',
      info: props.data?.status?.toUpperCase(),
    },
  ];

  const handleBtnCopy = useCallback((type: string) => {
    setBtnCopy({ [type]: true });
    setTimeout(() => {
      setBtnCopy({});
    }, 1000);
  }, []);

  async function handleSubmit() {
    const body = {
      id: Number(id),
      kleverResponse: textAreaValues,
      status: 'done',
    };
    const response = await api.patch({
      route: 'support-tickets/status',
      service: Service.KRYPTO_BANKING,
      apiVersion: 'v1',
      body,
    });

    if (response?.message) {
      return toast({
        title: t('common:toasts.titles.error'),
        description: ErrorHandler('adminAdministrators', response.message),
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } else {
      props.handleReload?.();
      props.onClose?.();
      return toast({
        title: t('common:toasts.titles.success'),
        description: t('common:toasts.body.successSent'),
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }
  }

  return (
    <BoxSection m="auto" width="100%" mt={1}>
      <Heading
        as="h3"
        size="lg"
        textAlign={'center'}
        color={theme.common.lightText}
        mb={5}
      >
        {t('modalTicket.depositInfo')}
      </Heading>
      {data_array.map(({ type, info }, index) =>
        type !== 'isNft' ? (
          <FormLabel htmlFor="id" color="gray.100" key={index}>
            {t(`statementsTable.${type}`)}
            <Skeleton
              isLoaded={!props.loading}
              fadeDuration={0.1}
              bg="white"
              color="white"
              height="32px"
              borderRadius="10px"
            >
              <Flex>
                <Input
                  value={info as any}
                  isReadOnly
                  inputType="modal"
                  data-testid="inputTest"
                />
                <Button
                  buttonType="copy"
                  data-testid="inputCopyTest"
                  onClick={() => {
                    navigator.clipboard.writeText(String(info));
                    handleBtnCopy(type);
                  }}
                >
                  {Object.keys(btnCopy).length !== 0 && btnCopy?.[type] ? (
                    <Tooltip label="Copied!" placement="top-start" isOpen>
                      <span>
                        <MdOutlineCheck
                          size={19}
                          color="green"
                          data-testid="copyIconTest"
                        />
                      </span>
                    </Tooltip>
                  ) : (
                    <MdContentCopy size={19} />
                  )}
                </Button>
              </Flex>
            </Skeleton>
          </FormLabel>
        ) : (
          <Box mb={3} mt={3} key={index}>
            <Text color={'white'} as="span" mr={3}>
              {t(`manageTicketTable.${type}`)}
            </Text>
            <Switch
              isChecked={Boolean(info)}
              mt={1}
              sx={{
                'span.chakra-switch__track': {
                  backgroundColor: theme.switch.colorOn,
                },
                'span.chakra-switch__track:not([data-checked])': {
                  backgroundColor: theme.switch.colorOff,
                },
              }}
            />
          </Box>
        ),
      )}
      {ABLED_BUTTON_SEND_TICKET_MODAL.includes(String(props.data?.status)) && (
        <>
          <FormLabel htmlFor="id" color="gray.100">
            Klever Response
            <Textarea
              value={textAreaValues}
              bg={theme.common.lightText}
              color="black"
              data-testid="textAreaInputTest"
              onChange={e => setTextAreaValue(e.target.value)}
            />
          </FormLabel>
          <Button
            mt={5}
            rightIcon={<MdSend />}
            type="submit"
            onClick={handleSubmit}
          >
            {t('logsTable.sendButton')}
          </Button>
        </>
      )}
    </BoxSection>
  );
}
